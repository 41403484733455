var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"house-custom"}},[_c('Header2022',{attrs:{"navbg":true}}),_c('div',{staticClass:"custom"},[_c('div',{staticClass:"left-wrap"}),_c('div',{staticClass:"custom-wrap"},[_vm._m(0),_c('div',{staticClass:"decorate"},[_c('div',{staticClass:"title"},[_vm._v("装修定制")]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"decorate-item"},[_c('i',{staticClass:"label special"},[_vm._v("数量详情：")]),_c('div',{staticClass:"num"},[_c('el-input-number',{attrs:{"min":1,"max":10,"label":"描述文字","size":"small"},on:{"change":_vm.handleChange},model:{value:(_vm.num),callback:function ($$v) {_vm.num=$$v},expression:"num"}}),_c('i',{staticClass:"name"},[_vm._v("了解施工详情>")])],1)]),_vm._m(6),_c('div',{staticClass:"type-btn reserve",on:{"click":_vm.reserveTap}},[_vm._v("预定户型")])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"house-type"},[_c('div',{staticClass:"title"},[_vm._v("户型定制")]),_c('img',{attrs:{"src":""}}),_c('div',{staticClass:"name"},[_vm._v(" 小院怡居E1户型_联排210m ")]),_c('div',{staticClass:"specifications"},[_c('i',[_vm._v("5室3厅3卫/温馨精致五室/三代同堂")]),_c('i',{staticClass:"price"},[_vm._v("¥1,450,000.00")])]),_c('div',{staticClass:"type-btn more"},[_vm._v("了解详情")]),_c('div',{staticClass:"type-btn reserve"},[_vm._v("预定户型")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"decorate-item"},[_c('i',{staticClass:"label"},[_vm._v("价格详情：")]),_c('i',{staticClass:"price"},[_vm._v("¥157,500")]),_c('i',{staticClass:"discount"},[_vm._v("0折")]),_c('i',{staticClass:"original-price"},[_vm._v("¥0")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"decorate-item"},[_c('i',{staticClass:"label"},[_vm._v("商家名称：")]),_c('i',{staticClass:"name"},[_vm._v("里亩装饰>")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"decorate-item"},[_c('i',{staticClass:"label"},[_vm._v("品牌名称：")]),_c('i',{staticClass:"name"},[_vm._v("里亩装饰>")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data"},[_c('div',{staticClass:"statistics"},[_vm._v(" 已售： "),_c('i',[_vm._v("0")])]),_c('div',{staticClass:"statistics"},[_vm._v(" 评分： "),_c('i',[_vm._v("0")])]),_c('div',{staticClass:"statistics"},[_vm._v(" 评价： "),_c('i',[_vm._v("0")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"decorate-item"},[_c('i',{staticClass:"label special"},[_vm._v("施工阶段：")]),_c('div',{staticClass:"phase"},[_c('i',[_vm._v("基础半包")]),_c('i',[_vm._v("硬装主材")]),_c('i',[_vm._v("板式定制")]),_c('i',[_vm._v("活动家具")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-wrap"},[_c('div',{staticClass:"type-btn buy"},[_vm._v("立即购买")]),_c('div',{staticClass:"type-btn buy"},[_vm._v("加入购物车")])])
}]

export { render, staticRenderFns }