<template>
	<div id="house-custom">
		<Header2022 :navbg='true'></Header2022>
		<div class="custom">
			<div class="left-wrap">
			</div>
			<div class="custom-wrap">
				<div class="house-type">
					<div class="title">户型定制</div>
					<img src="">
					<div class="name">
						小院怡居E1户型_联排210m
					</div>
					<div class="specifications">
						<i>5室3厅3卫/温馨精致五室/三代同堂</i>
						<i class="price">¥1,450,000.00</i>
					</div>
					<div class="type-btn more">了解详情</div>
					<div class="type-btn reserve">预定户型</div>
				</div>
				<div class="decorate">
					<div class="title">装修定制</div>
					<div class="decorate-item">
						<i class="label">价格详情：</i>
						<i class="price">¥157,500</i>
						<i class="discount">0折</i>
						<i class="original-price">¥0</i>
					</div>
					<div class="decorate-item">
						<i class="label">商家名称：</i>
						<i class="name">里亩装饰></i>
					</div>
					<div class="decorate-item">
						<i class="label">品牌名称：</i>
						<i class="name">里亩装饰></i>
					</div>
					<div class="data">
						<div class="statistics">
							已售：
							<i>0</i>
						</div>
						<div class="statistics">
							评分：
							<i>0</i>
						</div>
						<div class="statistics">
							评价：
							<i>0</i>
						</div>
					</div>
					<div class="decorate-item">
						<i class="label special">施工阶段：</i>
						<div class="phase">
							<i>基础半包</i>
							<i>硬装主材</i>
							<i>板式定制</i>
							<i>活动家具</i>
						</div>
					</div>
					<div class="decorate-item">
						<i class="label special">数量详情：</i>
						<div class="num">
							<el-input-number v-model="num" @change="handleChange" :min="1" :max="10" label="描述文字"
								size="small"></el-input-number>
							<i class="name">了解施工详情></i>
						</div>
					</div>
					<div class="btn-wrap">
						<div class="type-btn buy">立即购买</div>
						<div class="type-btn buy">加入购物车</div>
					</div>
					<div class="type-btn reserve" @click="reserveTap">预定户型</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import utils from '@/common/utils'
	import Header2022 from '@/components/header2022.vue'
	export default {
		components: {
			Header2022,
		},
		data() {
			return {
				num: 1,
				activeIndex: 0,
			}
		},

		methods: {
			handleChange(value) {
				console.log(value);
			},
			reserveTap() {
				this.$router.push({
					name: 'houseConfig'
				});
			}
		}
	}
</script>

<style lang="scss">
	#house-custom {
		color: #000000;

		.custom {
			width: 100%;
			display: flex;

			.left-wrap {
				width: 78.3%;
				height: 100vh;
			}

			.custom-wrap {
				flex: 1;
				width: 416px;
				padding: 64px 25px 54px;
				box-sizing: border-box;
				text-align: center;
				font-size: 16px;
				line-height: 16px;

				.title {
					font-size: 28px;
					font-weight: 800;
					line-height: 28px;
					margin: 40px 0 30px;
				}

				.name {
					font-weight: bold;
				}

				.specifications {
					display: flex;
					justify-content: space-between;
					font-size: 12px;
					color: #000000;
					font-weight: 200;
					margin: 2px 0 38px;

					.price {
						font-size: 20px;
						font-weight: 800;
						color: #07D8CC;
					}
				}

				.prompt {
					font-size: 16px;
					font-family: PingFang SC;
					font-weight: 200;
					color: #000000;
					margin: 40px 0 50px;
				}

				.type-btn {
					height: 40px;
					font-size: 14px;
					border-radius: 20px;
					line-height: 40px;
					width: 100%;
					text-align: center;

					&.more {
						border: 1px solid #9E9E9E;
						margin-bottom: 16px;
					}

					&.reserve {
						background: linear-gradient(16deg, #00C9FF 0%, #11ED84 100%);
					}

					&.buy {
						border: 1px solid #9E9E9E;
						width: 160px;
					}

				}

				.house-type {
					img {
						width: 366px;
						height: 136px;
						background-color: #CCCCCC;
						margin-bottom: 21px;
					}
				}

				.decorate {
					.decorate-item {
						text-align: left;
						margin-bottom: 25px;
						display: flex;

						.label {
							color: #9E9E9E;
							min-width: 80px;
						}

						.special {
							line-height: 44px;
						}

						.phase {
							display: flex;
							justify-content: space-between;
							flex-wrap: wrap;

							i {
								text-align: center;
								width: 134px;
								height: 44px;
								line-height: 44px;
								border: 2px solid #9E9E9E;
								border-radius: 12px;
								margin-bottom: 11px;

							}

						}

						.num {
							width: 100%;
							display: flex;
							justify-content: space-between;
							align-items: center;
						}

						.price {
							font-size: 20px;
							font-weight: 800;
							color: #07D8CC;
						}

						.discount {
							padding: 0 12px;
							font-size: 14px;
							color: #07D8CC;
							font-weight: 400;
							background: #D4FFFA;
							border-radius: 2px;
							margin: 0 5px 0 3px;
						}

						.original-price {
							font-weight: 500;
							text-decoration: line-through;
							color: #999999;
						}

						.name {
							font-weight: 500;
						}
					}

					.btn-wrap {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 11px;
					}

					.data {
						display: flex;
						color: #999999;
						font-size: 12px;
						font-weight: 500;
						line-height: 40px;
						margin-bottom: 18px;

						.statistics {
							flex: 33%;
							border: 1px dashed #999999;
							border-right: none;

							&:first-child {
								border-left: none;
							}

							i {
								font-size: 18px;
								font-weight: 400;
								color: #07D8CC;
								line-height: 40px;
							}
						}
					}
				}
			}
		}
	}
</style>